import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

const QuizPage = () => (
  <Layout
    selectedMenuItem="quiz"
    className="quiz"
    head={<script>{'var app_61994cfaf7c16a0018b466db;(function(d, t){var s=d.createElement(t),options={"appId":"61994cfaf7c16a0018b466db","width":"800","height":"800","async":true,"host":"quiz.tryinteract.com", "footer":"show"};s.src=\'https://i.tryinteract.com/embed/app.js\';s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs)if(rs!=\'complete\')if(rs!=\'loaded\')return;try{app_61994cfaf7c16a0018b466db=new InteractApp();app_61994cfaf7c16a0018b466db.initialize(options);app_61994cfaf7c16a0018b466db.display();}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr);})(document,\'script\');'}</script>}
  >
    <div className="row">
      <div id="interact-61994cfaf7c16a0018b466db" className="d-flex justify-content-center" />
      <p className="text-center pt-5">
        🍪 This quiz uses cookies. If you don&apos;t mind cookies, feel free to continue.
        {' '}
        <Link to="/privacy-web">Learn more.</Link>
      </p>
    </div>
  </Layout>
);

export default QuizPage;
